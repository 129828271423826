/* eslint-disable global-require */
export default [
    {
        hasNativeSupport: () => typeof Array.from === 'function',
        polyfill(resolve) {
            import('core-js/modules/es.array.from.js').then(resolve);
        },
    },
    {
        hasNativeSupport: () => typeof Array.of === 'function',
        polyfill(resolve) {
            import('core-js/modules/es.array.of.js').then(resolve);
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.includes === 'function',
        polyfill(resolve) {
            import('core-js/modules/es.array.includes.js').then(resolve);
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.find === 'function',
        polyfill(resolve) {
            import('core-js/modules/es.array.find.js').then(resolve);
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.findIndex === 'function',
        polyfill(resolve) {
            import('core-js/modules/es.array.find-index.js').then(resolve);
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.fill === 'function',
        polyfill(resolve) {
            import('core-js/modules/es.array.fill.js').then(resolve);
        },
    },
    {
        hasNativeSupport: () => typeof Object.assign === 'function',
        polyfill(resolve) {
            import('core-js/modules/es.object.assign.js').then(resolve);
        },
    },
    {
        hasNativeSupport: () => typeof Object.entries === 'function',
        polyfill(resolve) {
            import('core-js/modules/es.object.entries.js').then(resolve);
        },
    },
];
