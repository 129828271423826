import '../util/fixes';
import '../util/cms';
import { installPolyfills, handleErrors } from '../util/polyfills';
import 'custom-event-polyfill';

if (process.env.NODE_ENV === 'development') {
    document.body.classList.add('u-debug');
}

/**
 * Main entry point to the js app. Javascript execution starts here
 * 1. Webpack config and general fixes are initialized via `import` statement
 * 2. Polyfills are imported and asynchronously processed via `Promise` API
 * 3. In case of success, the vue app is initialized
 */
installPolyfills
    .then(() => import('../framework/base'))
    .then(app => app.init())
    .catch(handleErrors);
